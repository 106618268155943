import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import {
  setInventoryItems,
  setSelectedItem,
  deleteItem,
  updateInventoryItems,
} from '../../actions/inventory-actions';
import EditItemPopUp from './edit-item-popup';
import EditInventoryItemUserPopUp from './edit-inventory-item-user';
import { setUsers, setSelectedUser } from '../../actions/user-actions';
import { setLocations } from '../../actions/location-actions';
import Spinner from 'react-bootstrap/Spinner';
import formatDate from '../../utilities/date.utilities';
import { areArraysEqual } from '../../utilities/utilities';
import {
  getUsers,
  getLocations,
  getInventoryItems,
} from '../../utilities/api.calls';
import './styles.css';
import Checkbox from '@material-ui/core/Checkbox';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TableSortLabel,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { ActionButton, LocalTable, LocalModal, AddNew, Sort } from '../common';
import { Edit } from '@material-ui/icons';
import { TitlePanel } from '../common/LocalPanels';
import edit from '../../assets/images/icons/edit.svg';

class IndexInventory extends Component {
  constructor(props) {
    super(props);
    this.handleUserSelected = this.handleUserSelected.bind(this);
    this.getUserButtons = this.getUserButtons.bind(this);
    this.showAllInventoryItems = this.showAllInventoryItems.bind(this);
    this.showInventoryItemsOfActiveUsers = this.showInventoryItemsOfActiveUsers.bind(
      this
    );
    this.hideActiveUsersFilterButton = this.hideActiveUsersFilterButton.bind(
      this
    );
    this.showActiveUsersFilterButton = this.showActiveUsersFilterButton.bind(
      this
    );
    this.goToHome = this.goToHome.bind(this);
    this.state = {
      inventoryItems: [],
      inventoryItemsLoaded: false,
      errorMessage: '',
      allInventoryItems: [],
      activeUsersInventoryItems: [],
      selectedItemId: '',
      users: [],
      allUsers: [],
      activeUsers: [],
      selectedUserId: 'all',
      userFilterButtonText: 'ALL USERS',
      itemTypes: [],
      selectedItemType: 'all',
      typeFilterButtonText: 'Types',
      itemBrands: [],
      selectedItemBrand: 'all',
      brandFilterButtonText: 'Brands',
      modalTitle: '',
      sortField: '',
      sortDirection: 'asc',
      orderByUserAsc: true,
      orderByCodeAsc: true,
      orderByLocationAsc: true,
      activeUsersOnly: true,
      hideFilterActiveUsersButton: false,
      showInventoryItemDeletedMessage: false,
      baseAlertClass: 'text-center alert alert-dismissible fade show alert-',
      alertClass: '',
      confirmButton: true,
      itemDeletedMessage: '',
      processing: false,
      tableColumns: [
        { position: 1, field: 'type', label: 'Type', sortable: true },
        { position: 2, field: 'codeId', label: 'Code', sortable: true },
        { position: 3, field: 'brand', label: 'Brand', sortable: true },
        { position: 4, field: 'model', label: 'Model', sortable: true },
        { position: 5, field: 'user', label: 'Name', sortable: true },
        {
          position: 6,
          field: 'locationName',
          label: 'Location',
          sortable: true,
        },
        {
          className: 'd-none d-lg-table-cell',
          position: 7,
          field: 'detail',
          label: 'Detail',
          sortable: true,
        },
        {
          className: 'd-none d-lg-table-cell',
          style: { fontWeight: 'bold' },
          position: 8,
          field: 'lastUpdate',
          label: 'Last Update',
          sortable: true,
          type: 'date',
        },
        {
          position: 9,
          field: '',
          label: 'Actions',
          sortable: false,
          type: 'button',
          customComponent: (row) => {
            return (
              <div className="button-container">
                <ActionButton
                  dataToggle="modal"
                  dataTarget="#editItem"
                  handleAction={() => this.handleSelectedEdit(row.id)}
                  icon="fa fa-edit icon edit"
                  iconSrc={edit}
                  color=""
                />
                <ActionButton
                  dataToggle="modal"
                  dataTarget="#confirmDelete"
                  handleAction={async () => {
                    await this.setState({
                      deleteInventoryItemId: row.id,
                      deleteInventoryItemCodeId: row.codeId,
                      deleteInventoryItemType: row.type,
                      deleteInventoryItemBrand: row.brand,
                      deleteInventoryItemModel: row.model,
                      deleteInventoryItemLocation: row.location,
                      deleteInventoryItemDetail: row.detail,
                      deleteInventoryItemLastUpdate: formatDate(row.lastUpdate),
                    });
                  }}
                  icon="fas fa-trash-alt"
                  style={{ backgroundColor: '#FFC0C0' }}
                />
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount = () => {
    const inventoryItems = [],
      locations = [];
    this.props.setInventoryItems(inventoryItems, 0);
    this.props.setUsers(this.state.users);
    this.props.setLocations(locations);
  };

  componentDidUpdate = async (prevProps) => {
    const items = this.props.inventoryItems;
    let itemsUpdated = [];
    let i = 0;
    let locationName = '';
    let item;
    let inventoryItemsUpdated = false;
    const { selectedItem } = this.props;
    if (
      prevProps.selectedItem !== selectedItem &&
      typeof selectedItem !== 'undefined' &&
      selectedItem &&
      typeof selectedItem.itemDeleted !== 'undefined' &&
      typeof selectedItem.itemDeletedMessage !== 'undefined' &&
      selectedItem.itemDeletedMessage &&
      selectedItem.itemDeletedMessage.length > 0
    ) {
      const className = selectedItem.itemDeleted ? 'success' : 'danger';
      await this.setState({
        confirmButton: false,
        showInventoryItemDeletedMessage: true,
        itemDeletedMessage: selectedItem.itemDeletedMessage,
        alertClass: this.state.baseAlertClass + className,
        processing: false,
      });
      if (selectedItem.itemDeleted) {
        this.props.setSelectedItem('');
      }
    }
    if (
      !areArraysEqual(prevProps.inventoryItems, this.props.inventoryItems) &&
      this.props.inventoryItems.length > 0
    ) {
      let lookup = {},
        lookupType = {};
      itemsUpdated = [];
      inventoryItemsUpdated = false;
      const itemTypesResult = [];
      const itemBrandsResult = [];

      for (i = 0; i < items.length; i++) {
        item = items[i];
        let type = item.type;
        if (!(type in lookupType)) {
          lookupType[type] = 1;
          let itemType = {
            itemName: type,
            id: i,
          };
          itemTypesResult.push(itemType);
        }

        let brand = item.brand;
        if (!(brand in lookup)) {
          lookup[brand] = 1;
          let itemBrands = {
            itemName: brand,
            id: i,
          };
          itemBrandsResult.push(itemBrands);
        }
        if (this.props.locations.length > 0) {
          locationName = this.setItemLocationName(item) || '';
        }
        if (
          typeof item.locationName !== 'undefined' &&
          locationName !== '' &&
          item.locationName !== locationName
        ) {
          inventoryItemsUpdated = true;
          item.locationName = locationName;
        }
        itemsUpdated.push(item);
      }
      if (inventoryItemsUpdated) {
        this.props.updateInventoryItems(itemsUpdated);
        this.setState({...this.state, inventoryItems: itemsUpdated});
      }
      this.setState({
        ...this.state,
        itemTypes: itemTypesResult,
        itemBrands: itemBrandsResult,
        inventoryItems: itemsUpdated,
        activeUsersOnly: true,
        hideFilterActiveUsersButton: false,
      });
    }
    if (this.props.users.length > 0 && this.props.inventoryItems.length > 0) {
      let users = [];
      const userAux = {
        firstName: 'NOT',
        lastName: 'ASSIGNED',
        userStatusTypeId: '1',
        id: '-1',
      };
      users.push(userAux);
      this.props.users.map((user) => users.push(user));
      users.sort((a, b) =>
        a.id === '-1'
          ? -1
          : b.id === '-1'
          ? 1
          : String(a.firstName).toLowerCase() >
            String(b.firstName).toLowerCase()
          ? 1
          : String(b.firstName).toLowerCase() >
            String(a.firstName).toLowerCase()
          ? -1
          : 0
      );
      const inventoryItem = this.props.inventoryItems[0];
      if (
        typeof inventoryItem.user === 'undefined' ||
        inventoryItem.user === null
      ) {
        let inventoryItems = [];
        for (let i = 0; i < this.props.inventoryItems.length; i++) {
          item = this.props.inventoryItems[i];
          const usr = this.findUser(item, users);
          item['user'] = usr.firstName + ' ' + usr.lastName;
          item['isUserActive'] =
            usr.userStatusTypeId.toString() === '1' ? true : false;
          inventoryItems.push(item);
        }
        this.props.updateInventoryItems(inventoryItems);
        this.setState({...this.state, inventoryItems});
      }
      if (this.props.users !== prevProps.users) {
        const {
          activeUsersOnly,
          hideFilterActiveUsersButton,
          userFilterButtonText,
        } = this.state;
        const buttons = await this.getUserButtons(
          activeUsersOnly,
          hideFilterActiveUsersButton,
          users,
          userFilterButtonText
        );
        this.setState({
          users,
          buttons,
        });
      }
    }
    if (
      prevProps.locations !== this.props.locations &&
      this.props.locations.length > 0 &&
      this.props.inventoryItems.length > 0
    ) {
      itemsUpdated = [];
      inventoryItemsUpdated = false;
      for (i = 0; i < items.length; i++) {
        item = items[i];
        locationName = '';
        if (this.props.locations.length > 0) {
          locationName = this.setItemLocationName(item);
        }
        if (typeof item.locationName === 'undefined' && locationName !== '') {
          inventoryItemsUpdated = true;
          item['locationName'] = locationName;
        }
        if (
          typeof item.locationName !== 'undefined' &&
          locationName !== '' &&
          item.locationName !== locationName
        ) {
          inventoryItemsUpdated = true;
          item.locationName = locationName;
        }
        itemsUpdated.push(item);
      }
      if (inventoryItemsUpdated) {
        this.props.updateInventoryItems(itemsUpdated);
        this.setState({...this.state, inventoryItems: itemsUpdated});
      }
      this.setState({
        ...this.state,
        activeUsersOnly: true,
      });
    }
    if(this.props.locations !== undefined && this.props.locations !== null && this.props.locations.length > 0 &&
      this.props.inventoryItems !== undefined && this.props.inventoryItems !== null && this.props.inventoryItems.length > 0) {
        inventoryItemsUpdated = false;
        itemsUpdated = [];
      for (i = 0; i < items.length; i++) {
        item = items[i];
        locationName = this.setItemLocationName(item);
        if(locationName !== '' &&
        item.locationName !== locationName) {
          inventoryItemsUpdated = true;
          item.locationName = locationName;
        }
        itemsUpdated.push(item);
      }
      if (inventoryItemsUpdated) {
        this.props.updateInventoryItems(itemsUpdated);
        this.setState({...this.state, inventoryItems: itemsUpdated});
      }
    }
  };

  setItemLocationName = (item) => {
    let locationName = '';
    this.props.locations.forEach((location) => {
      if (item.locationId.toString() === location.id.toString()) {
        locationName = location.name;
      }
    });
    return locationName;
  };

  findUser = (item, users) => {
    const usr = users.find((usr) => {
      return usr.id === item.userId;
    });
    return usr;
  };

  handleEditUser = (event) => {
    this.props.setSelectedItem(event.target.value);
    this.setState({
      ...this.state,
      modalTitle: 'Edit User',
    });
  };

  handleSelectedEdit = (id) => {
    this.props.setSelectedItem(id);
    this.setState({
      ...this.state,
      modalTitle: 'Edit Item',
    });
  };

  deleteItem = (id) => {
    this.props.deleteItem(id);
  };

  handleNewItem = (event) => {
    this.props.setSelectedItem(0);
    this.setState({
      ...this.state,
      modalTitle: 'Add Item',
    });
  };

  handleUserSelected = async (event) => {
    const currentUserId = event.target.value;

    this.setState({
      ...this.state,
      selectedUserId: currentUserId,
    });
    this.props.setSelectedUser(currentUserId);
  };

  hideActiveUsersFilterButton = () => {
    let { buttons } = this.state;
    delete buttons[1];
    return buttons;
  };

  showActiveUsersFilterButton = () => {
    let { buttons, activeUsersOnly } = this.state;
    buttons[1] = {
      id: 2,
      hide: false,
      className: 'btn btn-sm btn-primary ml-2',
      onClick: this.showAllInventoryItems,
      title: 'Show All',
    };
    if (!activeUsersOnly) {
      buttons[1].title = 'Show Active users only';
      buttons[1].onClick = this.showInventoryItemsOfActiveUsers.bind(this);
    }
    return buttons;
  };

  handleItemTypeFilter = (event) => {
    const selectedItemType = event.target.value;
    const selectedItemTypeFilterText =
      event.target.value === '' ? 'Types' : selectedItemType;
    this.setState({
      ...this.state,
      selectedItemType: selectedItemType,
      typeFilterButtonText: selectedItemTypeFilterText,
    });
  };

  handleItemBrandFilter = (event) => {
    const selectedItemBrand = event.target.value;
    const selectedItemBrandFilterText =
      event.target.value === '' ? 'Brands' : selectedItemBrand;
    this.setState({
      ...this.state,
      selectedItemBrand: selectedItemBrand,
      brandFilterButtonText: selectedItemBrandFilterText,
    });
  };

  sortInventoryItems = (field, sortDirection) => {
    const asc = sortDirection === 'asc';
    let { inventoryItems } = this.props;
    inventoryItems = inventoryItems.sort((a, b) =>
      a[field].toLowerCase() > b[field].toLowerCase()
        ? asc
          ? 1
          : -1
        : b[field].toLowerCase() > a[field].toLowerCase()
        ? asc
          ? -1
          : 1
        : 0
    );
    this.props.updateInventoryItems(inventoryItems);
    this.setState({
      sortField: field,
      sortDirection: sortDirection,
    });
  };

  showAllInventoryItems = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {
      users,
      hideFilterActiveUsersButton,
      userFilterButtonText,
    } = this.state;
    const activeUsersOnly = false;
    const buttons = await this.getUserButtons(
      activeUsersOnly,
      hideFilterActiveUsersButton,
      users,
      userFilterButtonText
    );
    await this.setState({
      activeUsersOnly,
      buttons,
    });
  };

  showInventoryItemsOfActiveUsers = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {
      users,
      hideFilterActiveUsersButton,
      userFilterButtonText,
    } = this.state;
    const activeUsersOnly = true;
    const buttons = await this.getUserButtons(
      activeUsersOnly,
      hideFilterActiveUsersButton,
      users,
      userFilterButtonText
    );
    await this.setState({
      activeUsersOnly,
      buttons,
    });
  };

  onDelete = async (item) => {
    await this.setState({
      deleteInventoryItemId: item.id,
      deleteInventoryItemCodeId: item.codeId,
      deleteInventoryItemType: item.type,
      deleteInventoryItemBrand: item.brand,
      deleteInventoryItemModel: item.model,
      deleteInventoryItemLocation: item.location,
      deleteInventoryItemDetail: item.detail,
      deleteInventoryItemLastUpdate: formatDate(item.lastUpdate),
    });
  };
  getUserButtons = async (
    showOnlyActiveUsers,
    hideFilterActiveUsersButton,
    users,
    userFilterButtonText
  ) => {
    let buttons = [];
    buttons[0] = {
      id: 1,
      hide: false,
      title: userFilterButtonText,
      buttons: [],
    };
    buttons[0].buttons.push({
      id: 0,
      className: 'dropdown-item',
      title: 'ALL USERS',
      onClick: this.handleUserSelected.bind(this),
      value: '0',
    });
    if (!hideFilterActiveUsersButton) {
      buttons[1] = {
        id: 2,
        hide: false,
        className: 'btn btn-sm btn-primary ml-2',
        onClick: this.showAllInventoryItems.bind(this),
        title: 'Show All',
      };
      if (!showOnlyActiveUsers) {
        buttons[1].title = 'Show Active users only';
        buttons[1].onClick = this.showInventoryItemsOfActiveUsers.bind(this);
      }
    }
    users.forEach((user) => {
      if (
        (showOnlyActiveUsers && user.userStatusTypeId.toString() === '1') ||
        !showOnlyActiveUsers
      ) {
        buttons[0].buttons.push({
          id: '1' + user.id,
          onClick: this.handleUserSelected.bind(this),
          className: 'dropdown-item',
          value: user.id,
          title: user.firstName + ' ' + user.lastName,
        });
      }
    });
    return buttons;
  };

  handleCloseAlert = () => {
    this.props.setSelectedItem(undefined);
    this.setState({
      confirmButton: true,
      showInventoryItemDeletedMessage: false,
      itemDeletedMessage: '',
      alertClass: '',
      processing: false,
    });
  };

  goToHome = () => {
    this.props.history.push('/app');
  };

  render() {
    //const { inventoryItems } = this.props;
    const {
      buttons,
      inventoryItemsLoaded,
      errorMessage,
      sortField,
      sortDirection,
      itemTypes,
      selectedItemType,
      itemBrands,
      selectedItemBrand,
      selectedUserId,
      users,
      tableColumns,
      inventoryItems
    } = this.state;
    if (inventoryItems.length <= 0 || this.props.users.length <= 0) {
      return (
        <div style={{ textAlign: 'center', marginTop: '5rem' }}>
          <Spinner style={{ color: 'white' }} animation="border" />
        </div>
      );
    }

    const filterFields = (
      <FilterFields
        handleItemTypeFilter={this.handleItemTypeFilter}
        itemTypes={itemTypes}
        selectedItemType={selectedItemType}
        handleItemBrandFilter={this.handleItemBrandFilter}
        itemBrands={itemBrands}
        selectedItemBrand={selectedItemBrand}
        handleUserSelected={this.handleUserSelected}
        selectedUserId={selectedUserId}
        users={users}
      ></FilterFields>
    );

    return (
      <div>
        <TitlePanel
          {...this.props}
          title="Inventory"
          icon="fas fa-clipboard-list"
          total={inventoryItems.length}
          withFilter
        >
          {filterFields}
        </TitlePanel>

        <Filter
          {...this.props}
          handleNewItem={this.handleNewItem}
          sortField={sortField}
          handleSort={this.sortInventoryItems}
          tableColumns={tableColumns}
        >
          {filterFields}
        </Filter>
        <Items
          inventoryItems={inventoryItems.filter((item) => {
            return (
              (selectedItemType === 'all' || item.type === selectedItemType) &&
              (selectedItemBrand === 'all' ||
                item.brand === selectedItemBrand) &&
              (selectedUserId === 'all' || item.userId === selectedUserId)
            );
          })}
          sortDirection={sortDirection}
          sortField={sortField}
          handleSort={this.sortInventoryItems}
          handleSelectedEdit={this.handleSelectedEdit}
          handleDelete={this.onDelete}
          tableColumns={tableColumns}
        />

        <EditItemPopUp
          modalId="editItem"
          modalTitleId={this.state.modalTitle}
        />
        <EditInventoryItemUserPopUp
          modalId="editInventoryItemUser"
          modalTitleId="Change User"
        />
        <LocalModal
          modalName="confirmDelete"
          title="Confirm Delete"
          processing={this.state.processing}
          icon="fas fa-trash-alt"
          onConfirm={() => {
            this.props.deleteItem(this.state.deleteInventoryItemId);
            this.setState({ processing: true });
          }}
          onClose={this.handleCloseAlert}
          onConfirmText="Delete"
        >
          <div key="inventoryDelete">
            <div
              className={
                this.state.showInventoryItemDeletedMessage ? '' : 'd-none'
              }
            >
              <div className={this.state.alertClass} role="alert">
                {this.state.itemDeletedMessage}
                <button
                  type="button"
                  className="close"
                  onClick={this.handleCloseAlert}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div className="text-center">
              <div>CodeId: {this.state.deleteInventoryItemCodeId}</div>
              <div>Type: {this.state.deleteInventoryItemType}</div>
              <div>Brand: {this.state.deleteInventoryItemBrand}</div>
              <div>Model: {this.state.deleteInventoryItemModel}</div>
              <div>Location: {this.state.deleteInventoryItemLocation}</div>
              <div>Detail: {this.state.deleteInventoryItemDetail}</div>
              <div>Last Update: {this.state.deleteInventoryItemLastUpdate}</div>
            </div>
          </div>
        </LocalModal>
      </div>
    );
  }
}

const Card = ({
  id,
  codeId,
  type,
  brand,
  model,
  user,
  locationName,
  detail,
  lastUpdate,
  location,
  handleSelectedEdit,
  handleDelete,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="col-12 col-lg-3 col-md-4 col-sm-6 col-exsm-12">
      <div className="inventory-card">
        <div>{type}</div>
        <div className="card-grey">{brand}</div>
        <div className="card-blue">{codeId}</div>
        <div className="card-font-size-min">{user}</div>
        {open && (
          <>
            <div>
              <i className="fas fa-map-marker-alt location-icon" />
              <span className="card-font-size-min">{locationName}</span>
            </div>

            <div className="card-grey card-font-size-min">{detail}</div>
            <div className="action-buttons-card-container w-100 mt-1 d-flex justify-content-center">
              <ActionButton
                dataToggle="modal"
                dataTarget="#editItem"
                handleAction={() => handleSelectedEdit(id)}
                icon="fa fa-edit icon edit"
                iconSrc={edit}
                color=""
              />
              <ActionButton
                dataToggle="modal"
                dataTarget="#confirmDelete"
                handleAction={async () =>
                  handleDelete({
                    id,
                    codeId,
                    type,
                    brand,
                    model,
                    user,
                    detail,
                    lastUpdate,
                    location,
                  })
                }
                icon="fas fa-trash-alt"
                style={{ backgroundColor: '#FFC0C0', marginRight: 0 }}
              />
            </div>
          </>
        )}
        <div className="see-more-container">
          <div className="see-more" onClick={() => setOpen(!open)}>
            <i className={'fas fa-chevron-' + (open ? 'up' : 'down')} />
          </div>
        </div>
      </div>
    </div>
  );
};

const Filter = ({
  children,
  handleSort,
  sortField,
  handleNewItem,
  tableColumns,
}) => {
  return (
    <div className="container ">
      <div className="row filter-container">
        <div className="col-10 col-sm-10 col-md-3 col-lg-3 d-none">
          <div className="inner-addon right-addon">
            <i className="fa fa-search icon"></i>
            <input
              type="text"
              className="form-control search-input"
              placeholder="Search"
            />
          </div>
        </div>
        <div className="col-md-10 col-lg-10 d-none d-md-block">{children}</div>
        <div className="col-12 col-md-2 filter-buttons-container">
          {/* <ToggleButtonGroup
            orientation="vertical"
            value={'list'}
            exclusive
            name="list-type"
          >
            <ToggleButton value="list" aria-label="list">
              <AppsIcon />
            </ToggleButton>
            <ToggleButton value="module" aria-label="module">
              <ReorderIcon />
            </ToggleButton>
          </ToggleButtonGroup> */}
          <AddNew handleNew={handleNewItem} dataTarget="#editItem" />
          <Sort
            handleSort={handleSort}
            sortField={sortField}
            tableColumns={tableColumns}
          />
        </div>
      </div>
    </div>
  );
};

const FilterFields = ({
  selectedItemType,
  handleItemTypeFilter,
  itemTypes,
  selectedItemBrand,
  handleItemBrandFilter,
  itemBrands,
  selectedUserId,
  handleUserSelected,
  users,
}) => {
  return (
    <>
      <FormControlLabel
        className="label d-none"
        control={<Checkbox name="checkedB" color="primary" />}
        label="Show Active Only"
      />

      <FormControlLabel
        className="label d-none d-md-inline-flex"
        labelPlacement="start"
        control={
          <Select
            IconComponent={ExpandMore}
            disableUnderline
            className="select-filter"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            inputProps={{ 'aria-label': 'Without label' }}
            value={selectedItemType}
            onChange={handleItemTypeFilter}
          >
            <MenuItem value={'all'}>All</MenuItem>
            {itemTypes.map((item) => (
              <MenuItem key={item.itemName} value={item.itemName}>
                {item.itemName}
              </MenuItem>
            ))}
          </Select>
        }
        label="Type"
      />
      <FormControl
        variant="outlined"
        style={{ width: '80%', margin: '1em 0' }}
        className="d-md-none"
      >
        <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
        <Select
          IconComponent={ExpandMore}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          inputProps={{ 'aria-label': 'Without label' }}
          value={selectedItemType}
          onChange={handleItemTypeFilter}
          label="Type"
        >
          <MenuItem value={'all'}>All</MenuItem>
          {itemTypes.map((item) => (
            <MenuItem key={item.itemName} value={item.itemName}>
              {item.itemName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControlLabel
        labelPlacement="start"
        className="label d-none d-md-inline-flex"
        control={
          <Select
            IconComponent={ExpandMore}
            disableUnderline
            className="select-filter"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            inputProps={{ 'aria-label': 'Without label' }}
            value={selectedItemBrand}
            onChange={handleItemBrandFilter}
          >
            <MenuItem value={'all'}>All</MenuItem>
            {itemBrands.map((item) => (
              <MenuItem key={item.itemName} value={item.itemName}>
                {item.itemName}
              </MenuItem>
            ))}
          </Select>
        }
        label="Brand"
      />
      <FormControl
        variant="outlined"
        style={{ width: '80%', margin: '1em 0' }}
        className="d-md-none"
      >
        <InputLabel id="demo-simple-select-outlined-label">Brand</InputLabel>
        <Select
          IconComponent={ExpandMore}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedItemBrand}
          onChange={handleItemBrandFilter}
          label="Brand"
        >
          <MenuItem value={'all'}>All</MenuItem>
          {itemBrands.map((item) => (
            <MenuItem key={item.itemName} value={item.itemName}>
              {item.itemName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControlLabel
        labelPlacement="start"
        className="label d-none d-md-inline-flex"
        control={
          <Select
            IconComponent={ExpandMore}
            disableUnderline
            className="select-filter"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedUserId}
            onChange={handleUserSelected}
          >
            <MenuItem value={'all'}>All</MenuItem>
            {users.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.firstName + ' ' + user.lastName}
              </MenuItem>
            ))}
          </Select>
        }
        label="Users"
      />
      <FormControl
        variant="outlined"
        style={{ width: '80%', margin: '1em 0' }}
        className="d-md-none"
      >
        <InputLabel id="demo-simple-select-outlined-label">Users</InputLabel>
        <Select
          IconComponent={ExpandMore}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedUserId}
          onChange={handleUserSelected}
          label="Users"
        >
          <MenuItem value={'all'}>All</MenuItem>
          {users.map((user) => (
            <MenuItem key={user.id} value={user.id}>
              {user.firstName + ' ' + user.lastName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

const Cards = ({ inventoryItems, handleSelectedEdit, handleDelete }) => {
  return (
    <div className="container d-md-none cards-container">
      <div className="row">
        {inventoryItems.map((item) => (
          <Card
            key={item.codeId}
            {...item}
            handleSelectedEdit={handleSelectedEdit}
            handleDelete={handleDelete}
          />
        ))}
      </div>
    </div>
  );
};

const Items = (props) => {
  return (
    <>
      <Cards {...props} />
      <LocalTable
        {...{
          ...props,
          rows: props.inventoryItems,
          columns: props.tableColumns,
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    inventoryItems: state.inventoryStore.inventoryItems,
    selectedItem: state.inventoryStore.selectedItem,
    users: state.usersStore.users,
    locations: state.locationsStore.locations,
    itemUserUpdated: state.inventoryStore.itemUserUpdated,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setInventoryItems: (inventoryItems, userId) =>
    dispatch(setInventoryItems(inventoryItems, userId)),
  setSelectedItem: (selectedItemId) =>
    dispatch(setSelectedItem(selectedItemId)),
  setUsers: (users) => dispatch(setUsers(users)),
  setSelectedUser: (userId) => dispatch(setSelectedUser(userId)),
  deleteItem: (id) => dispatch(deleteItem(id)),
  updateInventoryItems: (inventoryItems) =>
    dispatch(updateInventoryItems(inventoryItems)),
  setLocations: (locations) => dispatch(setLocations(locations)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexInventory);
