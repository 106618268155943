import React from 'react';
import { Spinner } from 'react-bootstrap';
import './styles.css';

const LocalModal = ({
  children,
  icon,
  modalName,
  title,
  processing,
  onConfirm,
  onConfirmText = 'Save',
  onClose,
  alertClass = {},
  notification = '',
  onCloseNotification,
  hideCloseButton = false
}) => (
  <div id={modalName} className="modal" tabIndex="-1" role="dialog">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <LocalModalHeader
          icon={icon}
          title={title}
          handleClose={() => {
            onCloseNotification && onCloseNotification();
            onClose();
          }}
          hideCloseButton={hideCloseButton}
        />
        <div className={notification ? '' : 'd-none'}>
          <div className={alertClass} role="alert">
            {notification}
            <button
              type="button"
              className="close"
              onClick={onCloseNotification}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>

        <div className="modal-body">{children}</div>
        <LocalModalFooter processing={processing} text={onConfirmText} onConfirm={onConfirm}>
        </LocalModalFooter>
      </div>
    </div>
  </div>
);

const LocalModalHeader = ({ icon, title, handleClose, hideCloseButton = false }) => (
  <div
    className={
      'modal-header ' +
      (icon ? 'justify-content-between' : 'justify-content-start')
    }
  >
    <i className={icon + ' header-icon'}></i>

    <h5
      className="modal-title"
      id={title}
      style={{ fontSize: icon ? '18px' : '24px' }}
    >
      {title}
    </h5>
    {handleClose && !hideCloseButton && (
      <div
        data-dismiss="modal"
        aria-label="Close"
        className="modal-close"
        onClick={handleClose}
      >
        <i className="fa fa-times icon"></i>
      </div>
    )}
  </div>
);

const LocalModalFooter = ({ onConfirm, processing, text }) => {
  const [buttonPressed, setButtonPressed] = React.useState(false);

  const onButtonPressed = () => {
    setButtonPressed(true);
    onConfirm();
  }

  return (
  <div className="modal-footer">
    {processing ? (
      <Spinner
        className="mr-4"
        style={{ color: '#578db9' }}
        animation="border"
      />
    ) : (text === "Delete" && buttonPressed ? null :
      <button
        type="button"
        className="modal-save-button"
        onClick={onButtonPressed}
      >
        {text}
      </button>
    )}
  </div>
  );
};

export { LocalModalHeader, LocalModalFooter, LocalModal };
