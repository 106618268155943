import React from 'react';
import './styles.css';

const StepHeader = ({ step, onChangeStep }) => {
  const steps = Array.from(Array(6).keys());

  return (
    <div className="container step-dots-container">
      <div className="row">
        {steps.map((e, index) => (
          <div className="col p-0 d-flex justify-content-center">
            <div
              className="step-dot"
              style={{
                backgroundColor: index <= step ? '#80E616' : '#EDEDED',
                color: index <= step ? ' #F7F7F7' : '#D1D6DD',
              }}
              // onClick={()=>onChangeStep(index)}
            >
              {index}
            </div>
            {index !== 0 && (
              <div
                className="step-dot-line"
                style={{
                  left: '0',
                  backgroundColor: index <= step ? '#80E616' : '#EDEDED',
                }}
              ></div>
            )}
            {steps.length - 1 !== index && (
              <div
                className="step-dot-line"
                style={{
                  right: '0',
                  backgroundColor: index < step ? '#80E616' : '#EDEDED',
                }}
              ></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const StepHeaderProfile = ({ step, onChangeStep, admin }) => {
  return (
    <div className="container step-dots-container">
      <div className="row">
        <div className="col p-0 d-flex flex-column align-items-center justify-content-center ">
          <div
            className="step-dot"
            style={{
              backgroundColor: 1 == step ? '#80E616' : '#EDEDED',
              color: 1 == step ? ' #F7F7F7' : '#4b5668',
              cursor: 'pointer',
            }}
            onClick={() => onChangeStep(1)}
          >
            <i className="far fa-user"></i>
          </div>
          <span className="step-text">Personal Data</span>
        </div>
        <div className="col p-0 d-flex flex-column align-items-center justify-content-center ">
          <div
            className="step-dot"
            style={{
              backgroundColor: 2 == step ? '#80E616' : '#EDEDED',
              color: 2 == step ? ' #F7F7F7' : '#4b5668',
              cursor: 'pointer',
            }}
            onClick={() => onChangeStep(2)}
          >
            <i className="far fa-address-book"></i>
          </div>
          <span className="step-text">Contact</span>
        </div>
        <div className="col p-0 d-flex flex-column align-items-center justify-content-center ">
          <div
            className="step-dot"
            style={{
              backgroundColor: 3 == step ? '#80E616' : '#EDEDED',
              color: 3 == step ? ' #F7F7F7' : '#4b5668',
              cursor: 'pointer',
            }}
            onClick={() => onChangeStep(3)}
          >
            <i className="far fa-file-alt"></i>
          </div>
          <span className="step-text">Docs</span>
        </div>
        <div className="col p-0 d-flex flex-column align-items-center justify-content-center ">
          <div
            className="step-dot"
            style={{
              backgroundColor: 4 == step ? '#80E616' : '#EDEDED',
              color: 4 == step ? ' #F7F7F7' : '#4b5668',
              cursor: 'pointer',
            }}
            onClick={() => onChangeStep(4)}
          >
            <i className="fas fa-dollar-sign"></i>
          </div>
          <span className="step-text">Bank</span>
        </div>
        <div className="col p-0 d-flex flex-column align-items-center justify-content-center ">
          <div
            className="step-dot"
            style={{
              backgroundColor: 5 == step ? '#80E616' : '#EDEDED',
              color: 5 == step ? ' #F7F7F7' : '#4b5668',
              cursor: 'pointer',
            }}
            onClick={() => onChangeStep(5)}
          >
            <i className="far fa-file-image"></i>
          </div>
          <span className="step-text">Image</span>
        </div>
        {admin && (
          <>
            <div className="col p-0 d-flex flex-column align-items-center justify-content-center ">
              <div
                className="step-dot"
                style={{
                  backgroundColor: 6 == step ? '#80E616' : '#EDEDED',
                  color: 6 == step ? ' #F7F7F7' : '#4b5668',
                  cursor: 'pointer',
                }}
                onClick={() => onChangeStep(6)}
              >
                <i className="fas fa-tools"></i>
              </div>
              <span className="step-text">Admin</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const StepContainer = ({ children }) => (
  <div className="container step-container">
    <div className="col-12">{children}</div>
  </div>
);

const Welcome = ({ children, next }) => {
  return (
    <>
      <h1 className="welcome-title">
        Welcome to{' '}
        <span style={{ color: '#50A8FF', fontWeight: 'bold' }}>1950Labs</span>{' '}
        <span style={{ fontWeight: 'bold' }}>Dashboard</span>
      </h1>
      <h3 className="welcome-subtitle">We want to make you feel like home</h3>
      <p className="welcome-description">
        But first the registration form will only take you from 2 to 5 minutes,
        make sure you have your profile picture, bank account data and DGI.pdf
        ready to make this process.
      </p>
      <div className="col-12 text-center">
        <button type="button" onClick={next} className="next-button">
          Lets Start<i class="fas fa-arrow-right next-button-icon"></i>
        </button>
      </div>
    </>
  );
};

export { StepContainer, Welcome, StepHeader, StepHeaderProfile };
