import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  setProfileImage,
  setUser,
  updateUsersInStore,
  downloadImage,
} from '../../actions/user-actions';
import Cookies from 'universal-cookie';
import moment from 'moment';
import { Services } from '../../api/services';
import Navbar from '../main/navbar';
import { getUserFromProps } from '../../utilities/utilities';
import PersonalFormData from './registerForm/personal-form-data';
import BankFormData from './registerForm/bank-form-data';
import IndependentFormData from './registerForm/independent-form-data';
import DependentFormData from './registerForm/dependent-form-data';
import AdminFormData from './registerForm/admin-form-data';
import { getUserType, getPropertyValue } from './registerForm/userUtilities';
import {
  StepContainer,
  StepHeaderProfile,
} from './registerForm/step-container';
import PersonalFormDataStep2 from './registerForm/personal-form-data-step-2';
import MultimediaFormData from './registerForm/multimedia-form-data';
import { TitlePanel } from '../common/LocalPanels';

class AdminSection extends Component {
  constructor(props) {
    super(props);
    this.goToHome = this.goToHome.bind(this);
    this.state = {
      users: [],
      locations: [],
      user: {
        firstName: '',
        lastName: '',
        email: '',
        userEmail: '',
        password: '',
        userStatus: '',
        locationId: '',
        actualPassword: '',
        newPassword: '',
        confirmEmail: '',
        confirmPassword: '',
        changeEmailPassword: '',
      },
      emailError: '',
      confirmEmailError: '',
      changeEmailPasswordError: '',
      firstNameError: '',
      lastNameError: '',
      actualPasswordError: '',
      newPasswordError: '',
      confirmPasswordError: '',
      baseAlertClass: 'text-center alert alert-dismissible fade show alert-',
      alertClass: '',
      showProfileUpdatedMessage: false,
      profileUpdatedMessage: '',
      selectedUserId: '',
      processing: false,
      userLocationButtonText: '',
      selectedLocationId: 1,
      changePassword: false,
      file: {},
      uploadImage: false,
      profileImage: '',
      editMode: false,
      changeEmail: false,
      saved: false,
      step: 1,
      role: {
        id: '',
        name: '',
      },
    };
  }

  getUser = async (userId) => {
    if (typeof userId !== 'undefined' && userId && userId !== '') {
      const user = await Services.Users.getUser(userId)
        .then((response) => {
          return response.val();
        })
        .catch((error) => {
          console.log(error);
        });
      if (user) {
        //getLocations(user);
        this.setState({
          ...this.state,
          user: {
            ...this.state.user,
            id: user.id,
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            homeAddress: user.homeAddress || '',
            cellularNumber: user.cellularNumber || '',
            personalEmail: user.personalEmail || '',
            identificationType: user.identificationType || '',
            identificationNumber: user.identificationNumber || '',
            nationality: user.nationality || '',
            birthDate:
              getPropertyValue(user.birthDate) === ''
                ? moment(new Date()).format('MM/DD/YYYY')//new Date().toLocaleDateString()
                : user.birthDate,
            calendarBirthDate:
              getPropertyValue(user.birthDate) === ''
                ? new Date()
                : new Date(user.birthDate),
            email: user.email,
            locationId: user.locationId,
            needsToFillRegister: user.needsToFillRegister,
            userRoleId: user.userRoleId,
            userStatusTypeId: user.userStatusTypeId,
            userTypeId: user.userTypeId,
            file: {
              ...this.state.user.file,
              name:
                typeof user.file !== 'undefined' &&
                user.file !== null &&
                typeof user.file.name !== 'undefined' &&
                user.file.name !== ''
                  ? user.file.name
                  : '',
              url:
                typeof user.file !== 'undefined' &&
                user.file !== null &&
                typeof user.file.url !== 'undefined' &&
                user.file.url !== ''
                  ? user.file.url
                  : require('../../assets/images/default-user.png'),
            },
          },
          userId,
        });
        console.log(user);
        const role = await getUserType(user.id);
        await this.setState({
          ...this.state,
          role,
        });
      }
    }
  };
  
  componentDidMount = async () => {
    const userId = new Cookies().get('1950SystemUserId');
    console.log(this.props.user)
    if (typeof this.props.user !== 'undefined' && this.props.user) {
      let user = getUserFromProps(this.props.user);
      const role = await getUserType(user.id);
      this.setState({ ...this.state, role });
      if (!user.file) {
        user.file = {};
        user.file.url = require('../../assets/images/default-user.png');
        console.log("entre a !user.file");
        console.log(user);
        this.setState({ ...this.state, user, userId: user.id });
      } else {
        if (!user.file.url) {
          const userFile =
            getPropertyValue(user.file) !== ''
              ? user.id + '/' + user.file.name
              : '';
              console.log("entre a !user.file.url");
              console.log(user);
          Services.Users.downloadImage(userFile)
            .then((data) => {
              if (getPropertyValue(data) !== '') {
                user.file.url = data;
                if (user.id === userId) this.props.setProfileImage(data);
              } else {
                user.file = {};
                user.file.url = require('../../assets/images/default-user.png');
              }
              this.setState({ ...this.state, user, userId: user.id });
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          console.log("entre al else de !user.file.url")
          console.log(user);
          const role = await getUserType(user.id);

          await this.setState({ ...this.state, user, userId: user.id, role });
        }
      }
    } else {
      console.log("entre al else de afuera")
      if (this.state.userId === undefined || this.state.userId === '') {
        console.log("entre al if de obtener user con userId: " + userId);
        this.getUser(userId);
      }
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (
      typeof this.props.user !== 'undefined' &&
      this.props.user !== null &&
      typeof prevProps !== 'undefined' &&
      prevProps !== null &&
      typeof prevProps.user !== 'undefined' &&
      prevProps.user !== null &&
      this.props.user.id !== prevProps.user.id &&
      this.props.user.id !== this.state.user.id
    ) {
      let user = getUserFromProps(this.props.user);
      const role = await getUserType(user.id);
      if (!user.file) {
        user.file = {};
        user.file.url = require('../../assets/images/default-user.png');
      }
      this.setState({ ...this.state, user, userId: user.id, role });
    }
  };

  onChangeImage = async (e) => {
    let files = e.target.files;
    if (files.length > 0) {
      const oldFileName =
        getPropertyValue(this.state.user.file) !== ''
          ? this.state.user.file.name
          : '';
      await Services.Users.uploadImageOnStorage(
        this.state.user.id,
        files[0],
        oldFileName
      )
        .then(async (response) => {
          if (response.success === true) {
            const file = {
              name: files[0].name,
              type: files[0].type,
              url: response.downloadUrl,
            };
            const fileResponse = await Services.Users.updateUserFileOnly(
              this.state.user.id,
              file
            );
            if (fileResponse.success === true) {
              //TODO: update users and user variables in the store
              if (getPropertyValue(fileResponse.user) !== '') {
                let users = this.props.users;
                if (getPropertyValue(users) !== '') {
                  users = users.map((user) => {
                    if (user.id === this.state.userId) {
                      user = fileResponse.user;
                    }
                  });
                } else {
                  users = [];
                  users.push(fileResponse.user);
                }
                const userId = new Cookies().get('1950SystemUserId');
                if (fileResponse.user.id === userId)
                  this.props.setUser(users, fileResponse.user);
                else {
                  this.props.updateUsersInStore(users);
                }
              }
              if (
                getPropertyValue(file) !== '' &&
                getPropertyValue(file.url) !== ''
              ) {
                this.props.setProfileImage(file.url);
              }
              this.setState({
                ...this.state,
                user: {
                  ...this.state.user,
                  file: {
                    ...this.state.user.file,
                    name: file.name,
                    url: file.url,
                  },
                },
              });
              this.showNotification(
                'Profile image uploaded successfully',
                'success'
              );
            } else {
              this.showNotification(
                'Error trying to upload profile image',
                'danger'
              );
            }
          } else {
            this.showNotification(
              'Error trying to upload profile image',
              'danger'
            );
          }
        })
        .catch((error) => {
          this.showNotification(
            'Error trying to upload profile image',
            'danger'
          );
        });
    }
  };

  updateUser = async (user) => {
    await this.setState({ processing: true });
    delete user.calendarBirthDate;
    delete user.password;
    let message = { userUpdated: false, message: '' };
    try {
      //this.onChangePassword(user);

      const response = await Services.Users.updateProfile(user)
        .then((data) => {
          if (typeof data !== 'undefined' && data !== null) {
            if (data.userUpdated) {
              data.users.forEach((userData) => {
                if (userData.id === data.id) {
                  userData.email = data.email;
                  userData.firstName = data.firstName;
                  userData.lastName = data.lastName;
                  userData.locationId = user.locationId ? user.locationId : 1;
                  if (data.uploadImage) {
                    userData['file'] = data.file;
                  }
                }
              });

              message.userUpdated = data.userUpdated;
              message.message = data.userUpdatedMessage;

              const users = data.users;
              delete data.users;
              delete data.userUpdated;
              delete data.userUpdatedMessage;
              const userId = new Cookies().get('1950SystemUserId');
              if (data.id === userId) this.props.setUser(users, data);
              else {
                this.props.updateUsersInStore(users);
              }
              this.setState({ processing: false });
              return data;
            } else {
              message.userUpdated = false;
              message.message = data.userUpdatedMessage;
            }
          } else {
            message.userUpdated = false;
            message.message = 'Error when updating the user profile';
          }
          this.setState({ processing: false });
          return message;
        })
        .catch((error) => {
          message.userUpdated = false;
          message.message = 'Error when updating the user profile';
          this.setState({ processing: false });
          return message;
        });
    } catch (err) {
      message.userUpdated = false;
      message.message = 'Error when updating the user profile';
      this.setState({ processing: false });
      return message;
    }

    if (message.userUpdated) {
      this.showNotification(message.message, 'success');
    } else {
      this.showNotification(message.message, 'danger');
    }
  };

  updateLocalUser = (user) => {
    this.setState({ user: { ...this.state.user, ...user } });
  };
  
  handleCloseNotification = () => {
    this.setState({
      baseAlertClass: 'text-center alert alert-dismissible fade show alert-',
      alertClass: '',
      showProfileUpdatedMessage: false,
      message: '',
    });
  };

  goToHome = () => {
    this.props.history.push('/app');
  };

  changeStep = (step) => {
    if (this.state.step !== step) {
      this.setState({
        ...this.state,
        step,
      });
    }
  };

  showNotification = (message, type) => {
    if (type === 'success' || type === 'danger') {
      this.setState({
        ...this.state,
        message,
        alertClass: this.state.baseAlertClass + type,
      });
    }
  };
  render() {
    return (
      <React.Fragment>
        <TitlePanel
          {...this.props}
          icon="far fa-user"
          title="Edit User"
          onBack={this.props.goBack}
        ></TitlePanel>
        <div
          className={this.state.message ? '' : 'd-none'}
          style={{ height: '40px' }}
        >
          <div
            className={this.state.alertClass}
            style={{ height: '40px' }}
            role="alert"
          >
            {this.state.message}
            <button
              type="button"
              className="close"
              onClick={this.handleCloseNotification}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>

        <StepHeaderProfile
          step={this.state.step}
          onChangeStep={this.changeStep}
          admin
        />
        <StepContainer>
          {this.state.step === 1 && (
            <PersonalFormData
              updateLocalUser={this.updateLocalUser}
              updateUser={this.updateUser}
              user={this.state.user}
              title="Personal Data"
              nextText="Save Changes"
              handleCloseNotification={this.handleCloseNotification}
              profile
              admin
            />
          )}
          {this.state.step === 2 && (
            <PersonalFormDataStep2
              updateLocalUser={this.updateLocalUser}
              updateUser={this.updateUser}
              user={this.state.user}
              title="Contact"
              nextText="Save Changes"
              handleCloseNotification={this.handleCloseNotification}
              profile
            />
          )}
          {this.state.step === 3 &&
            this.state.role &&
            this.state.role.name &&
            (this.state.role.name === "Independiente" || this.state.role.name === "Operaciones-Factura") && (
              <IndependentFormData
                user={this.state.user}
                next={this.next}
                notify={this.showNotification}
                title="Complete your company information"
                nextText="Save Changes"
                handleCloseNotification={this.handleCloseNotification}
                profile
              />
            )}
          {this.state.step === 3 &&
            this.state.role &&
            this.state.role.name &&
            (this.state.role.name === "Dependiente" || this.state.role.name === "Operaciones-Nómina") && (
              <DependentFormData
                user={{id: this.state.user.id}}
                userTypeId={this.state.role.id}
                notify={this.showNotification}
                next={this.next}
                title="Upload your employee information"
                nextText="Save Changes"
                handleCloseNotification={this.handleCloseNotification}
                profile
              />
            )}
          {this.state.step === 4 && (
            <BankFormData
              user={this.state.user}
              notify={this.showNotification}
              title="Complete your bank data so we can pay you"
              next={this.next}
              nextText="Save Changes"
              handleCloseNotification={this.handleCloseNotification}
              profile
            />
          )}
          {this.state.step === 5 && (
            <MultimediaFormData
              updateLocalUser={this.updateLocalUser}
              updateUser={this.updateUser}
              user={this.state.user}
              title="Now it's time to upload some files"
              next={this.next}
              toHome={this.goToHome}
              notify={this.showNotification}
              handleCloseNotification={this.handleCloseNotification}
              nextText="Save Changes"
              profile
            />
          )}
          {this.state.step === 6 && (
            <AdminFormData
              userId={this.state.userId}
              notify={this.showNotification}
              comeFromProfile={true}
              handleCloseNotification={this.handleCloseNotification}
              title="Admin"
            />
          )}
        </StepContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.usersStore.users,
    selectedUserId: state.usersStore.selectedUserId,
    locations: state.locationsStore.locations,
    profileImage: state.usersStore.profileImage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setProfileImage: (imageUrl) => dispatch(setProfileImage(imageUrl)),
  setUser: (users, user) => dispatch(setUser(users, user)),
  updateUsersInStore: (users) => dispatch(updateUsersInStore(users)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminSection);
