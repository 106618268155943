import React, { Component } from 'react';
import NavigationPopUp from './navigation-popup';
import Spinner from 'react-bootstrap/Spinner';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  getButtonNavigationItems,
  getNavigationItems,
  getUserRolesButtonNavigation,
  setUserRoles,
  setButtonNavigationItems,
  setButtonNavigationItemsForButton,
  deleteButtonNavigation,
  setButtonNavigationInfo,
} from '../../actions/general-actions';
import Cookies from 'universal-cookie';
import { setSelectedButton } from '../../actions/general-actions';
import { FaSortAlphaDown, FaSortAlphaUp } from 'react-icons/fa/index';
import DeleteSimpleModal from '../shared/simple-modal/delete-simple-modal';
import Navbar from '../main/navbar';
import { Services } from '../../api/services';
import { ActionButton, AddNew, LocalModal, LocalTable, Sort } from '../common';
import edit from '../../assets/images/icons/edit.svg';
import { TitlePanel } from '../common/LocalPanels';
import './styles.css';

class IndexNavigation extends Component {
  constructor(props) {
    super(props);
    this.handleSelectedEdit = this.handleSelectedEdit.bind(this);
    this.goToHome = this.goToHome.bind(this);
    this.state = {
      selectedButtonId: '',
      orderByTitleAsc: false,
      orderByDescriptionAsc: false,
      orderByLinkAsc: false,
      buttonAddedOrUpdated: false,
      buttonNavigationItemsUpdated: false,
      confirmButton: true,
      baseAlertClass: 'text-center alert alert-dismissible fade show alert-',
      showButtonDeletedMessage: false,
      buttonDeletedMessage: '',
      alertClass: '',
      processing: false,
      buttons: [
        {
          id: 1,
          className: 'btn btn-sm btn-success mr-2',
          dataToggle: 'modal',
          dataTarget: '#createButton',
          title: 'Add Button',
          onClick: this.handleSelectedEdit.bind(this),
        },
      ],
      buttonNavigationItemsWereLoaded: false,
      sortField: '',
      sortDirection: 'asc',
      tableColumns: [
        {
          position: 1,
          field: 'title',
          label: 'Title',
          sortable: true,
        },

        {
          position: 2,
          className: 'd-none d-lg-table-cell',
          field: 'description',
          label: 'Description',
          sortable: true,
        },
        {
          className: 'd-none d-lg-table-cell',
          position: 3,
          field: 'title',
          label: 'Link',
          sortable: true,
          customComponent: (row) => {
            return (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={row.link}
                style={{ color: ' #50a8ff' }}
              >
                {row.link}
              </a>
            );
          },
        },
        {
          position: 7,
          field: 'roles',
          label: 'Roles with access',
          sortable: true,
        },
        {
          stylesHeader: { textAlign: 'right' },
          stylesCell: { textAlign: 'right' },
          position: 9,
          field: '',
          label: 'Actions',
          sortable: false,
          type: 'button',
          customComponent: (row) => {
            return (
              <div className="button-container">
                <ActionButton
                  row={row}
                  dataToggle="modal"
                  dataTarget="#createButton"
                  handleAction={() => this.handleSelectedEdit(row.id)}
                  icon="fa fa-edit icon edit"
                  iconSrc={edit}
                  color=""
                />
                <ActionButton
                  row={row}
                  dataToggle="modal"
                  dataTarget="#confirmDelete"
                  handleAction={async () => {
                    await this.setState({
                      deleteButtonId: row.id,
                      deleteButtonTitle: row.title,
                      deleteButtonDescription: row.description,
                      deleteButtonLink: row.link,
                      deleteButtonRoles: row.roles,
                    });
                  }}
                  icon="fas fa-trash-alt"
                  style={{ backgroundColor: '#FFC0C0' }}
                />
              </div>
            );
          },
        },
      ],
    };
  }

  getUserRolesButtonNavigation = (navigation) => {
    Services.General.getUserRolesButtonNavigation()
      .then((userRoleBtnNavigationResponse) => {
        let userRolesButtonNavigation = [];
        if (userRoleBtnNavigationResponse !== null)
          userRolesButtonNavigation =
            userRoleBtnNavigationResponse.userRolesButtonNavigation;
        this.getRoles(navigation, userRolesButtonNavigation);
      })
      .catch((error) => {
        this.getRoles(navigation, []);
      });
  };

  getRoles = (navigation, userRolesButtonNavigation) => {
    Services.General.getUserRoles()
      .then((data) => {
        let userRoles = [];
        if (data.val() !== null) {
          const response = data.val();
          for (var key in response) {
            userRoles.push(response[key]);
          }
        }
        this.saveInfo(navigation, userRolesButtonNavigation, userRoles);
      })
      .catch((error) => {
        this.saveInfo(navigation, userRolesButtonNavigation, []);
      });
  };

  saveInfo = (navigation, userRolesButtonNavigation, userRoles) => {
    const currentUserId = new Cookies().get('1950SystemUserId');
    this.props.setButtonNavigationInfo(
      navigation,
      userRolesButtonNavigation,
      userRoles
    );
    this.setState({
      currentUserId,
      buttonNavigationItemsWereLoaded: true,
    });
  };

  componentDidMount = () => {
    const currentUserId = new Cookies().get('1950SystemUserId');

    Services.General.getButtonNavigationItems(currentUserId)
      .then((response) => {
        let navigation = [];
        if (response !== null) navigation = response.navigation;
        this.getUserRolesButtonNavigation(navigation);
      })
      .catch((error) => {
        this.getUserRolesButtonNavigation([]);
      });
  };

  componentDidUpdate = async (prevProps) => {
    let button = this.props.button;
    if (prevProps.button !== button) {
      if (button.buttonAdded || button.buttonUpdated) {
        this.setState({
          buttonAddedOrUpdated: true,
        });
        this.props.getUserRolesButtonNavigation();
      }
      if (
        typeof button !== 'undefined' &&
        button &&
        typeof button.buttonDeleted !== 'undefined' &&
        typeof button.buttonDeletedMessage !== 'undefined' &&
        button.buttonDeletedMessage &&
        button.buttonDeletedMessage.length > 0
      ) {
        const className = button.buttonDeleted ? 'success' : 'danger';
        await this.setState({
          confirmButton: false,
          showButtonDeletedMessage: true,
          buttonDeletedMessage: button.buttonDeletedMessage,
          alertClass: this.state.baseAlertClass + className,
          processing: false,
        });
      }
    }
    let buttonNavigationItems = this.props.buttonNavigationItems;
    let userRolesButtonNavigation = this.props.userRolesButtonNavigation;
    let userRoles = this.props.userRoles;
    let btnNavItemId = 0;
    let roleId = -1;
    //let btnIndex;
    let roleIdString = '';
    let userRoleIdString = '';
    let btnUserRolesToCompare = '';
    let btnUserRoles = '';
    let buttonRoleIds = [];
    if (
      typeof buttonNavigationItems !== 'undefined' &&
      buttonNavigationItems &&
      typeof userRolesButtonNavigation !== 'undefined' &&
      userRolesButtonNavigation &&
      typeof userRoles !== 'undefined' &&
      userRoles
    ) {
      btnUserRoles =
        typeof this.props.button !== 'undefined' && this.props.button
          ? typeof this.props.button.roles !== 'undefined' &&
            this.props.button.roles
            ? this.props.button.roles
            : ''
          : typeof buttonNavigationItems[buttonNavigationItems.length - 1] !==
              'undefined' &&
            buttonNavigationItems[buttonNavigationItems.length - 1]
          ? buttonNavigationItems[buttonNavigationItems.length - 1].roles
          : '';
      let btnUserRolesString = '';
      btnUserRolesToCompare = '';
      let userRoleBtnNav;
      buttonNavigationItems.map((btnNavItem, index) => {
        btnUserRolesString = '';
        btnNavItemId = btnNavItem.id;
        //btnIndex = index;
        buttonRoleIds = [];
        Object.keys(userRolesButtonNavigation).forEach(
          (userRoleBtnNavIndex) => {
            userRoleBtnNav = userRolesButtonNavigation[userRoleBtnNavIndex];
            roleIdString = '';
            roleId = userRoleBtnNavIndex;
            buttonRoleIds.push(roleId);
            if (typeof roleId === 'undefined' || roleId === null) {
              console.log('roleId no está definido');
            } else {
              roleIdString = roleId.toString();
            }
            Object.keys(userRoleBtnNav).forEach((btnNavId) => {
              if (btnNavItemId === btnNavId) {
                userRoles.forEach((userRole) => {
                  userRoleIdString = '';
                  userRoleIdString = userRole.id.toString();
                  if (roleIdString === userRoleIdString) {
                    if (btnUserRolesString === '') {
                      btnUserRolesString += userRole.name;
                    } else {
                      btnUserRolesString += ', ' + userRole.name;
                    }
                  }
                });
              }
            });
          }
        );

        btnUserRolesString += '.';
        btnNavItem['roles'] = btnUserRolesString;
        if (
          typeof this.props.button !== 'undefined' &&
          typeof this.props.button.id !== 'undefined' &&
          this.props.button &&
          typeof this.props.button.roles &&
          this.props.button.id.toString() === btnNavItemId.toString()
        ) {
          btnUserRolesToCompare = btnUserRolesString;
        }
        return (buttonNavigationItems[index]['rolesIds'] = buttonRoleIds);
      });
      if (
        btnUserRolesToCompare !== '' &&
        btnUserRoles !== btnUserRolesToCompare
      ) {
        button['buttonAdded'] = button['buttonUpdated'] = false;
        button['roles'] = btnUserRolesToCompare;
        this.setState({
          buttonNavigationItemsUpdated: true,
        });
        this.props.setButtonNavigationItemsForButton(
          buttonNavigationItems,
          button
        );
      } else {
        if (
          typeof this.props.button === 'undefined' &&
          btnUserRoles !== btnUserRolesString
        ) {
          this.setState({
            buttonNavigationItemsUpdated: true,
          });
          this.props.setButtonNavigationItems(buttonNavigationItems);
        }
      }
    }
  };

  handleSelectedEdit = async (id) => {
    
    this.setState({
      selectedButtonId: id,
    });

    this.props.setSelectedButton(id);
  };

  onDelete = async (row) => {
    await this.setState({
      deleteButtonId: row.id,
      deleteButtonTitle: row.title,
      deleteButtonDescription: row.description,
      deleteButtonLink: row.link,
      deleteButtonRoles: row.roles,
    });
  }

  sortLinks = (field, sortDirection) => {
    const asc = sortDirection === 'asc';
    let { buttonNavigationItems } = this.props;
    buttonNavigationItems = buttonNavigationItems.sort((a, b) =>
      a[field].toLowerCase() > b[field].toLowerCase()
        ? asc
          ? 1
          : -1
        : b[field].toLowerCase() > a[field].toLowerCase()
        ? asc
          ? -1
          : 1
        : 0
    );

    this.setState({
      buttonNavigationItems: buttonNavigationItems,
      sortField: field,
      sortDirection: sortDirection,
    });
  };

  handleCloseAlert = () => {
    this.props.setSelectedButton(undefined);
    this.setState({
      confirmButton: true,
      showButtonDeletedMessage: false,
      buttonDeletedMessage: '',
      alertClass: '',
      processing: false,
    });
  };

  goToHome = () => {
    this.props.history.push('/app');
  };

  render() {
    const { buttonNavigationItems } = this.props;
    const {
      sortField,
      sortDirection,
      tableColumns,
      buttonNavigationItemsWereLoaded,
    } = this.state;
    if (!buttonNavigationItemsWereLoaded) {
      return (
        <React.Fragment>
          <div style={{ textAlign: 'center', marginTop: '5rem' }}>
            <Spinner style={{ color: 'white' }} animation="border" />
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <TitlePanel
          title="Navigation"
          total={buttonNavigationItems.length}
          history={this.props.history}
          icon="fas fa-link"
        />
        <Filter
          {...this.props}
          handleNewItem={() => this.handleSelectedEdit('')}
          sortField={sortField}
          handleSort={this.sortLinks}
          tableColumns={tableColumns}
        ></Filter>

        <Items
          items={buttonNavigationItems}
          sortDirection={sortDirection}
          sortField={sortField}
          handleSort={this.sortLinks}
          tableColumns={tableColumns}
          handleSelectedEdit={this.handleSelectedEdit}
          handleDelete={this.onDelete}
        />

        <NavigationPopUp
          modalId="createButton"
          modalTitleId={
            this.state.selectedButtonId === ''
              ? 'Create Button'
              : 'Update Button'
          }
        />

        <LocalModal
          modalName="confirmDelete"
          title="Confirm Delete"
          processing={this.state.processing}
          icon="fas fa-trash-alt"
          onConfirm={() => {
            this.props.deleteButtonNavigation(this.state.deleteButtonId);
            this.setState({ processing: true });
          }}
          onClose={this.handleCloseAlert}
          onConfirmText="Delete"
        >
          <div key="buttonDelete">
            <div
              className={this.state.showButtonDeletedMessage ? '' : 'd-none'}
            >
              <div className={this.state.alertClass} role="alert">
                {this.state.buttonDeletedMessage}
                <button
                  type="button"
                  className="close"
                  onClick={this.handleCloseAlert}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div className="text-center">
              <div>Title: {this.state.deleteButtonTitle}</div>
              <div>Description: {this.state.deleteButtonDescription}</div>
              <div>Link: {this.state.deleteButtonLink}</div>
              <div>Roles: {this.state.deleteButtonRoles}</div>
            </div>
          </div>
        </LocalModal>
      </React.Fragment>
    );
  }
}

const Filter = ({
  children,
  handleSort,
  sortField,
  handleNewItem,
  tableColumns,
}) => {
  return (
    <div className="container ">
      <div className="row filter-container">
        <div className="col-10 col-sm-10 col-md-3 col-lg-3 d-none">
          <div className="inner-addon right-addon">
            <i className="fa fa-search icon"></i>
            <input
              type="text"
              className="form-control search-input"
              placeholder="Search"
            />
          </div>
        </div>
        <div className="col-md-10 col-lg-10 d-none d-md-block">{children}</div>
        <div className="col-12 col-md-2 filter-buttons-container">
          <AddNew handleNew={handleNewItem} dataTarget="#createButton" />
          <Sort
            handleSort={handleSort}
            sortField={sortField}
            tableColumns={tableColumns}
          />
        </div>
      </div>
    </div>
  );
};

const Items = (props) => {
  return (
    <>
      <Cards {...props} />
      <LocalTable
        {...{
          ...props,
          rows: props.items,
          columns: props.tableColumns,
        }}
      />
    </>
  );
};

const Card = ({ id, title, description, link, roles, handleSelectedEdit, handleDelete}) => {
  const [open, setOpen] = React.useState(false);
  return (
    <div className="col-12 col-lg-3 col-md-4 col-sm-6 col-exsm-12">
      <div className="inventory-card">
        <div>{title}</div>
        <div className="card-grey">{description}</div>
        <div className="card-blue">
          <a
            target="_blank"
            className="card-link"
            rel="noopener noreferrer"
            href={link}
          >
            {link}
          </a>
        </div>
        <div className="card-font-size-min">{roles}</div>
        {open && (
          <>
            <div className="action-buttons-card-container w-100 mt-1 d-flex justify-content-center">
              <ActionButton
                dataToggle="modal"
                dataTarget="#createButton"
                handleAction={() => handleSelectedEdit(id)}
                icon="fa fa-edit icon edit"
                iconSrc={edit}
                color=""
              />
              <ActionButton
                dataToggle="modal"
                dataTarget="#confirmDelete"
                handleAction={() => handleDelete({
                  id, title, description, link, roles
                })}
                icon="fas fa-trash-alt"
                style={{ backgroundColor: '#FFC0C0' }}
              />
            </div>
          </>
        )}
        <div className="see-more-container">
          <div className="see-more" onClick={() => setOpen(!open)}>
            <i className={'fas fa-chevron-' + (open ? 'up' : 'down')} />
          </div>
        </div>
      </div>
    </div>
  );
};

const Cards = ({ items, handleSelectedEdit, handleDelete }) => {
  return (
    <div className="container d-md-none cards-container">
      <div className="row">
        {items.map((item) => (
          <Card 
          key={'link_' + item.title} 
          {...item}
          handleSelectedEdit={handleSelectedEdit}
          handleDelete={handleDelete}
          />
        ))}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    button: state.generalStore.button,
    buttonNavigationItems: state.generalStore.buttonNavigationItems,
    navigationItems: state.generalStore.navigationItems,
    userRolesButtonNavigation: state.generalStore.userRolesButtonNavigation,
    userRoles: state.generalStore.userRoles,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSelectedButton: (buttonId) => dispatch(setSelectedButton(buttonId)),
  getButtonNavigationItems: (currentUserId) =>
    dispatch(getButtonNavigationItems(currentUserId)),
  getNavigationItems: (currentUserId) =>
    dispatch(getNavigationItems(currentUserId)),
  getUserRolesButtonNavigation: () => dispatch(getUserRolesButtonNavigation()),
  setUserRoles: (roles) => dispatch(setUserRoles(roles)),
  setButtonNavigationItems: (buttonNavigationItems) =>
    dispatch(setButtonNavigationItems(buttonNavigationItems)),
  setButtonNavigationItemsForButton: (buttonNavigationItems, button) =>
    dispatch(setButtonNavigationItemsForButton(buttonNavigationItems, button)),
  deleteButtonNavigation: (id) => dispatch(deleteButtonNavigation(id)),
  setButtonNavigationInfo: (navigation, userRolesButtonNavigation, userRoles) =>
    dispatch(
      setButtonNavigationInfo(navigation, userRolesButtonNavigation, userRoles)
    ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IndexNavigation)
);
